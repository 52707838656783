footer {
  padding-top: 2em;
  padding-bottom: 0.5em;
  color: $about-text-color;
  background-color: $footer-background-color;
  text-align: center;
  position: relative;
}

footer a,
footer a:visited {
  color: $inactive-accent-color;
}

footer a:hover, footer a:focus {
   color: #fff; 
}

footer ul {
  list-style: none outside;
  font-size: 2em;
  display: flex;
  justify-content: space-around;
  flex-wrap: nowrap;
}

footer li {
  margin: 0;
  padding: 0;
  line-height: 1em;
}

footer li:hover {
  -webkit-transform: scale(1.3, 1.3);
  -moz-transform: scale(1.3, 1.3);
  -ms-transform: scale(1.3, 1.3);
  -o-transform: scale(1.3, 1.3);
  transform: scale(1.3, 1.3)
}

footer li:hover {
  border: 0.1em solid $accent-color;
  box-shadow: 0 0 0.1em $accent-color;
  transition: all 0.5s ease;
}