@import url('https://fonts.googleapis.com/css2?family=Libre+Baskerville:ital@1&family=Open+Sans:ital,wght@0,300;0,500;0,700;0,800;1,300;1,600&display=swap');

$header-font: 'Open Sans', sans-serif;
h2, h3, h4, h5 {
  font-family: $header-font;
}

h4, h5 {
   color: $header-text-color;
}
h6 span.title{
  font-family: 'Libre Baskerville', serif
}

