$accent-color: #fe6928;
$inactive-accent-color: #525252;
$background-about-color: #000524;
$about-text-color: #ccc;
$secondary-accent-color: #740001;
$header-text-color: #313131;
$text-color: #6E7881;
$data-color: rgb(116 0 1);
$header-color: #838C95;
$footer-color: #303030;
$footer-background-color: #0f0f0f;

main {
  color: $text-color;
}

.selected-skill {
  color: $accent-color;
}
