.hero {
  height: 100vh;
  justify-content: center;
  background: url(../images/portrait.png);
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}

.sticky {
  position: sticky;
  top: 0;
  width: 100%;
  z-index: 4;
}

.sticky+main {
  padding-top: 60px;
}

.center-text {
  justify-content: center;
  text-align: center;
  color: white;
  text-shadow: 0 0 1px black, 0 0 1px black, 0 0 1px black, 0 0 1px black;
  text-transform: capitalize;

  .row {
    height: 100%;
  }

  .col {
    h1 {
      font-size: calc(10vw);

    }

    h3 {
      font-size: calc(4vw);
      opacity: 1;
    }
  }

}
