@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";

@import "styles/Colours.scss";
@import "styles/Fonts.scss";
@import "styles/Navbar.scss";
@import "styles/About.scss";
@import "styles/Resume.scss";
@import "styles/Footer.scss";
@import "styles/Hero.scss";
@import "styles/Skills.scss";

$primary: $accent-color;

p {
  line-height: 2;
}

body {
  position: relative;
}
#projects .carousel-item img {
  max-height: 250px;
}


@import "~bootstrap/scss/bootstrap";