.bars ul {
  list-style: none;
}

.bars li {
  position: relative;
  margin-bottom: 60px;
  background: rgb(153, 151, 151);
  height: 20px;
  border-radius: 3px;
  cursor: pointer;

  span {
    background-color: $data-color;
    position: absolute;
    left: 0;
    top: 0;
    margin: 0;
    padding-right: 24px;
    display: inline-block;
    height: 20px;
    line-height: 20px;
    border-radius: 3px;
  }

  @for $i from 1 through 9 {
    span.level-#{$i} {
      width: #{$i*10%}
    }
  }


  em {
    font-family: 'Open Sans', sans-serif;
    font-style: normal;
    font-weight: bold;
    color: $footer-background-color;
    text-transform: uppercase;
    letter-spacing: 2px;
    font-weight: normal;
    position: relative;
    top: -36px;
  }
}

.bars li.selected {
  border-color: black;
  box-shadow: 0 0 0.1em $accent-color;
  border-width: 1px;
  border-style: solid;
  font-weight: bolder;
  em {
    color: $accent-color;
  }
  span {
    height: 18px;
  }
}

#skills {
  padding-top: 5em;
}
