h6 span {
  font-family: inherit;
  font-weight: inherit;
  line-height: inherit;
}

main h2, main h5, main .h5 {
  font-weight: 600;
}
main h2, main h6 {
  margin-bottom: 1em;  
}

main h4 {
  padding: 1em;
  text-transform: uppercase;
  text-align: left;
  padding-bottom: 6px;
  font-size: medium;
}

main h4 span {
  padding-left: 0.2em;
  padding-right: 0.2em;
  border-bottom: 3px solid $secondary-accent-color;
}

main section {
  padding-bottom: 1em;
  padding-top: 1em;
}

.accordion-button .date {
  color: $text-color;
}

.selected.accordion-item {
  h5 {
    color: $accent-color;
  }
}